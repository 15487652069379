<template>
  <Portlet
    :title="portletTilte"
    icon="band-aid"
    class="acknowledgeProblemAll"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div
        v-if="triggers"
        :class="['mb-3', { 'is-invalid': errors.has(`TriggerIds`) }]"
      >
        <h4 class="m-0 p-0 mb-1">
          {{ $t('triggers') }}
        </h4>
        <hr class="m-0 p-0 mb-2">
        <ul class="m-0 p-0">
          <li
            v-for="(trigger, triggerIndex) in triggers"
            :key="`acknowledgeProblemAllTriggerIndexListItem-${ triggerIndex }`"
          >
            <label class="m-checkbox">
              <input
                v-model="selectedTriggers"
                :value="trigger"
                type="checkbox"
                class="mr-2"
              >
              <input type="checkbox"> {{ trigger.hosts[0] ? trigger.hosts[0].name : '' }}: {{ trigger.description }}
              <span />
            </label>
            <!-- <br> -->
            <!-- <span class="ml-4">{{ trigger.comments }}</span> -->
          </li>
        </ul>
        <span
          v-show="errors.has(`TriggerIds`)"
          class="badge badge-danger"
        >{{ errors.first(`TriggerIds`) }}</span>
      </div>

      <div
        v-if="triggers"
        class="row"
      >
        <div class="col-12 col-sm-9">
          <div class="form-group m-form__group">
            <label>{{ $t('message') }}</label>
            <textarea
              v-model="message"
              v-focus
              :class="['form-control m-input', { 'is-invalid': errors.has(`Message`) }]"
              placeholder=""
              @keydown.ctrl.enter="acknowledge()"
            />
            <span
              v-show="errors.has(`Message`)"
              class="badge badge-danger"
            >{{ errors.first(`Message`) }}</span>
          </div>
          <div class="row">
            <div class="form-check form-check-inline mb-3">
              <input
                id="changeSeverityCheckbox"
                v-model="changeServerity"
                class="form-check-input"
                type="checkbox"
              >
              <label
                class="form-check-label"
                for="changeSeverityCheckbox"
              >Change Severity</label>
            </div>
            <select
              v-if="changeServerity"
              id="converterTypes"
              v-model="selectedSeverity"
              class="form-control mb-4"
            >
              <option
                v-for="(severity, index) in severities"
                :key="`converterType-converterTypeOption-${ index }`"
                :value="index+''"
              >
                {{ severity }}
              </option>
            </select>
          </div>
          <div
            class="row"
            style="justify-content: end;"
          >
            <button
              class="btn btn-primary float-right"
              @click.prevent="acknowledge()"
            >
              {{ $t('acknowledgeAll') }}
            </button>
          </div>
          <div class="clearfix" />
        </div>
        <div class="col-12 col-sm-3">
          <label>{{ $t('possibleMessages') }}</label>
          <button
            v-for="(item, index) in messages"
            :key="`acknowledgeProblem-messageButton-${ index }`"
            class="btn btn-block btn-secondary"
            @click="setMessage(item.key)"
          >
            {{ item.key }}
          </button>
        </div>
      </div>

      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </Portlet>
</template>

<script>
import acknowledgeReportAdapter from '@/adapter/acknowledgeReportAdapter.js';
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "AcknowledgeProblemAll",
  mixins: [
    acknowledgeReportAdapter,
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: false,
      default () {
        return 'installation'
      }
    }
  },
  data () {
    return {
      triggers: null,
      loading: true,
      message: "",
      messages: null,
      selectedTriggers: [],
      installation: null,
      changeServerity: false,
      selectedSeverity: null,
      severities: ['Not classified', 'Information', 'Warning', 'Average', 'High', 'Disaster']
    }
  },
  computed: {
    areAllSelected () {
      return this.triggers.length === this.selectedTriggers.length;
    },
    portletTilte () {
      if (this.installation == null) {
        return 'Acknowledge multiple';
      } else {
        return `Acknowledge multiple: ${ this.installation.name }`;
      }
    }
  },
  created () {
    this.getTrigger();
    this.getInstallation();
    this.getAcknowledgeMessages();
  },
  methods: {
    getInstallation: function () {
      this.loading = true;
      this.axios.get(`/Installation/Get?Id=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          this.installation = response.data;
        });
    },
    setMessage (key) {
      let lowerCaseKey = key.toLowerCase();
      let messageToSet = this.messages.find(message => {
        return message.key.toLowerCase() === lowerCaseKey;
      });
      if (messageToSet == null) {
        return;
      }
      this.message = messageToSet.message;
    },
    getTrigger: function () {
      this.loading = true;
      this.axios.get(`/MonitoringSystem/GetTriggersByInstallationId?installationId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            this.loading = false;
            return;
          }
          let temporaryTriggers = response.data;
          //this.selectedTriggers = JSON.parse(JSON.stringify(temporaryTriggers)); //THIS WAS BUG SKILINE-13628
          this.triggers = temporaryTriggers.filter(trigger => {
            return trigger.acknowledged == false;
          });
          this.selectedTriggers = JSON.parse(JSON.stringify(this.triggers));
        });
    },
    getAcknowledgeMessages: function () {
      this.loading = true;
      this.axios.get('/AcknowledgeMessages/GetAllAcknowledgeMessages')
        .then((response) => {
          if (response == null) {
            return;
          }
          this.messages = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    acknowledge () {
      if (this.areAllSelected) {
        this.acknowledgeAll();
      } else {
        this.acknowledgeSelected();
      }
    },
    // acknowledge all events of this triggers
    acknowledgeAll () {
      this.loading = true;
      let action = 0;
      if(this.changeServerity) {
        action = 8;
      }

      let acknowledgeRequest = this.sendAcknowledgeAllReport(this.installationId, this.message, action, this.selectedSeverity);
      acknowledgeRequest.then(() => {
          this.error_clear();
          
          if (this.source === 'problem') {
            this.$router.push({path: `/problem`});
            return;
          }
          this.$router.push({path: `/installation/${ this.installationId }`});
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
          this.loading = false;
        });
    },
    // acknowledge selected events of this triggers
    acknowledgeSelected () {
      this.loading = true;
      let action = 0;
      if(this.changeServerity) {
        action = 8;
      }

      let acknowledgeRequest = this.sendAcknowledgeSelectedReport(this.message, this.selectedTriggers, action, this.selectedSeverity);
      acknowledgeRequest.then(() => {
          this.error_clear();
          
          if (this.source === 'problem') {
            this.$router.push({path: `/problem`});
            return;
          }
          this.$router.push({path: `/installation/${ this.installationId }`});
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
          this.loading = false;
        });
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.acknowledgeProblemAll ul,
.acknowledgeProblemAll li {
  list-style: none;
}
.is-invalid .m-checkbox > span {
  border-color: #f4516c !important;
}
.acknowledgeProblemAll .sbx-google__submit {
  display: none;
}
.acknowledgeProblemAll .sbx-google__input,
.acknowledgeProblemAll .sbx-google__wrapper,
.acknowledgeProblemAll .searchbox.sbx-google {
  width: 100%;
}
.acknowledgeProblemAll .is-invalid .sbx-google__input {
  border: 1px solid red;
  box-shadow: none;
}

</style>
